<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-4>
            <v-layout row justify-space-between align-center>
              <v-flex xs6>
                <span class="heading">Medical Officer List</span>
              </v-flex>
              <v-layout wrap justify-end pt-2>
                <v-flex xs12 sm6 md6 lg6 >
                  <v-text-field
                        :hide-details="true"
                         v-model="hospitalName"
                          append-icon="mdi-magnify"
                          label="Search"
                          solo  clearable
                          dense flat outlined
                         style="font-family:sofiaProRegular;font-size:13px;"
                        ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-flex xs6 class="text-right">
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="getExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel
                    <v-icon color="black">mdi-microsoft-excel</v-icon>
                  </span>
                </v-btn>
              </v-flex> -->
            </v-layout>
            
            <!-- <v-flex xs12>
              <span class="heading">Rescuer List</span>
            </v-flex>
            <v-flex xs12>
              <v-btn
                color="#fdac53"
                class="downlink"
                target="_blank"
                @click="getExcel()"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: black;
                  "
                >
                  Excel<v-icon color="black">mdi-microsoft-excel</v-icon></span
                >
              </v-btn>
            </v-flex> -->
            <v-flex xs12 py-4 v-if="userlist && userlist.length > 0">
              <v-layout wrap justify-start pt-2>
                <v-flex xs12>
                  <v-card>
                    <div style="overflow-y: auto;">
                    <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left tablehead">Hospital Name</th>
                             
                              <th class="text-left tablehead">Phone</th>
                              <th class="text-left tablehead">District Name</th>
                              <th class="text-left tablehead">Category</th>
                              
                              <!-- <th class="text-left tablehead">Delete</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in userlist" :key="index">
                              <td style="white-space: nowrap;" class="tableitems">{{item.name}}</td>
                              <td style="white-space: nowrap;" class="tableitems">{{item.phone}}</td>
                              <td style="white-space: nowrap;" class="tableitems">{{item.Districtname}}</td>
                              <td style="white-space: nowrap;" class="tableitems">{{item.category}}</td>
                             
                              
                              <!-- <td>
                                <v-btn
                                  color="error"
                                  depressed
                                  small
                                 @click="rejectDialog(item.user._id)"
                                  ><span>Delete</span></v-btn
                                >
                              </td> -->
                              <!-- <td>
                                <v-btn
                                  color="error"
                                  depressed
                                  small
                                  @click="openrejectDialog(item)"
                                  ><span>Reject</span></v-btn
                                >
                              </td> -->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="userlist && userlist.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else>
          <v-layout
            wrap
            justify-center
            align-center
           
          >
            <v-flex xs12 class="text-center">
              <span class="nodata">No Data Found !</span>
            </v-flex>
          </v-layout>
        </v-flex>
        
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Delete Medical Officer</v-card-title>
          <v-card-text>
            Are you sure you want to delete this rescuer?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteUser()"
              class="itemValue"
            >
              <span style="color: #fff">Confirm</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
        <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        hospitalName: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        districts: [
          "Palakkad",
          "Alappuzha",
          "Malappuram",
          "Kannur",
          "Wayanadu",
          "Kozhikode",
          "Thrissur",
          "Thiruvananthapuram",
          "Kottayam",
          "Idukki",
          "Ernakulam",
          "Kollam",
          "Pathanamthitta",
          "Kasaragod",
        ],
        deleteDialog: false,
      };
    },
    beforeMount() {
      this.getData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      hospitalName() {
        this.currentPage=1
        this.getData();
      },
    },
    methods: {
    //   openviewdetails(item) {
    //     this.$router.push({
    //       path: "/rescuerslistview",
    //       query: {
    //         id: item._id,
    //       },
    //     });
    //   },
    //   getExcel() {
    //     this.appLoading = true;
    //     axios({
    //       method: "GET",
    //       url: "/rescuer/getlist/download/excel",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       responseType: "blob",
    //       params: {
    //         keyword: this.keyword,
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         const url = URL.createObjectURL(
    //           new Blob([response.data], {
    //             type: "application/vnd.ms-excel",
    //           })
    //         );
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", "file.xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
    //   rejectDialog(item) {
    //     this.deleteDialog = true;
    //     this.deleteid = item;
    //   },
    //   openeditdetails(item) {
    //     this.$router.push({
    //       path: "/newrescuerslistedit",
    //       query: {
    //         id: item.user._id,
    //       },
    //     });
    //   },
    //   deleteUser() {
    //     this.appLoading = true;
    //     axios({
    //       method: "DELETE",
    //       url: "/user/delete",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         id: this.deleteid,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status == true) {
    //           {
    //             this.msg = "Deleted Successfully.";
    //             this.showSnackBar = true;
    //             this.appLoading = false;
    //             this.deleteDialog = false;
    //           }
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //           this.appLoading = false;
    //           this.deleteDialog = false;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         (this.ServerError = true), console.log(err);
    //       });
    //   },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/admin/medicalofficer/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            count: this.count,
            page: this.currentPage,
            hospitalName: this.hospitalName,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.userlist = response.data.data;
                this.Pagelength = response.data.pages;
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
    //   handlesubmit() {
    //     this.appLoading = true;
    //     axios({
    //       method: "POST",
    //       url: "/snake/",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         name: this.commonname,
    //         scientificName: this.scientificname,
    //         states: this.state,
    //         rname: this.reginonalname,
    //         distribution: this.distribution,
    //         characteristics: this.scalation,
    //         description: this.description,
    //         venomType: this.venomtype,
    //         family: this.family,
    //         occurence: this.occurence,
    //         wlpa: this.wlpa,
    //         iucn: this.iucn,
    //         shortdes: this.shortdescription,
    //         othernames: this.othername,
    //         genus: this.genus,
    //         experts: this.experts,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status == true) {
    //           {
    //             this.msg = response.data.msg;
    //             this.showSnackBar = true;
    //             this.appLoading = false;
    //             this.commonname = "";
    //             this.scientificname = "";
    //             this.state = "";
    //             this.reginonalname = "";
    //             this.distribution = "";
    //             this.scalation = "";
    //             this.description = "";
    //             this.venomtype = "";
    //             this.family = "";
    //             this.occurence = "";
    //             this.wlpa = "";
    //             this.iucn = "";
    //             this.shortdescription = "";
    //             this.othername = "";
    //             this.genus = "";
    //             this.experts = "";
    //           }
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //           this.appLoading = false;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         (this.ServerError = true), console.log(err);
    //       });
    //   },
    },
  };
  </script>
        <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>